import './DashboardSquareBlock.scss'

const DashboardSquareBlock = ({ number, title }) => {
    return (
        <div className="dashboard_square">
            <h2>{number}</h2>
            <p>{title}</p>
        </div>
    )
}

export default DashboardSquareBlock