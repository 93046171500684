import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import Dashboard from "../../AdminsBlock/Dashboard"
import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock"
import './Admins.scss'

export default function Admins({ token, onLogout }) {
    const [appState, setAppState] = useState({ loading: false })
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)

    const getUSers = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/users`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result)
            })
    }

    useEffect(() => {
        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }

        getUSers()
    }, [])



    return (
        <div className="container_admins">
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="container_admins_rightPart" >
                <h3>Admins</h3>
                <Dashboard data={data} />
                <Link
                    to='/add-new-user'
                >
                    <button>
                        <p>Add new</p>
                    </button>
                </Link>
            </div>
        </div>
    )
}
