import './Auth.scss'
import AuthList from './AuthList'


export default function AuthorizationData({ data }) {
    return (
        <div className='auth_list'>
            <div className='auth_list_header' >
                <p>IP</p>
                <p>Authorization </p>
                <p>Last request </p>
                <p>Platform </p>
                <p>Notifications</p>
            </div>
            <div>
                {
                    data.sessions?.map((value, index) => {
                        return (
                            <AuthList key={index} ip={value.last_ip} authorization={value.auth_time ? value.auth_time.split('T')[0] + ' ' + value.auth_time.split('T')[1].split('.')[0] : null} lastRequest={value.last_request ? value.last_request.split('T')[0] + ' ' + value.last_request.split('T')[1].split('.')[0] : null} platform={value.platform} notification={value.notification_enabled === true ? 'On' : 'Off'} />
                        )

                    })
                }
            </div>
        </div>
    )
}
