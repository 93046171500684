import { useState } from "react"
import { useEffect } from "react"
import DashboardSquareBlock from "../../Blocks/Dashboard/DashboardSquare/DashboardSquareBlock"
import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock"
import './DashboardScreen.scss'



const DashboardScreen = ({ token, onLogout }) => {
    const [appState, setAppState] = useState({ loading: false })
    const [data, setData] = useState({})
    const dashboardFunction = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch('https://apiv1.zis.ru/dashboard', requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result)
            })
    }


    useEffect(() => {

        setAppState({ loading: true });
        dashboardFunction()
    }, [setAppState])


    return (
        <div className="container_dashboard">
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="dashboard_right">
                <h3>Now</h3>
                <div className="dashboard_right_divs" >
                    <DashboardSquareBlock number={data.devices_all} title={'All Devices'} />
                    <DashboardSquareBlock number={data.devices_online} title={'Devices Online'} />
                    <DashboardSquareBlock number={data.accounts_all} title={'Clients Total'} />
                </div>
                <div className="marginDiv">
                    <h3>Last 24 hours</h3>
                    <div className="dashboard_right_divs" >
                        <DashboardSquareBlock number={data.devices_new_24h} title={'New Devices'} />
                        <DashboardSquareBlock number={data.devices_online_24h} title={'Devices Online'} />
                        <DashboardSquareBlock number={data.accounts_24h} title={'Clients'} />
                    </div>
                </div>
                <div className="marginDiv">
                    <h3>Last 30 days</h3>
                    <div className="dashboard_right_divs" >
                        <DashboardSquareBlock number={data.devices_new_30d} title={'New Devices'} />
                        <DashboardSquareBlock number={data.devices_online_30d} title={'Devices Online'} />
                        <DashboardSquareBlock number={data.accounts_30d} title={'Clients'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardScreen
