import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DeviceDetailsList from "../../Blocks/Devices/DevicesBlock/DeviceDetails/DeviceDetailsList";
import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock";
import './TestScreen.scss'

export default function TestScreen({ onLogout, token }) {
    const pathName = window.location.href
    const id = pathName.split('/')
    const ides = id[id.length - 1]
    const [data, setData] = useState([])


    const getConfiguration = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/tests/panel/${ides}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result) {
                    setData(result)
                }
            })
            .catch(error => console.log(error, 'errorr'))
    }

    let voltage = data.voltage_min + "-" + data.voltage_max + ' ' + 'V'
    let voltageNull = 0 + '-' + 0 + ' ' + 'V'
    let amperage = data.amperage_min + "-" + data.amperage_max + ' ' + 'A'
    let amperageNull = 0 + '-' + 0 + ' ' + 'A'
    let power = data.power_min + '-' + data.power_max
    let power_null = 0 + '-' + 0


    const checkInProgress = (dateStart, dateEnd, date) => {
        return date > dateStart && date < dateEnd;
    }

    const checkSchedult = (dateStart, date) => {
        return date < dateStart
    }

    const checkWillBeDeleted = (dateEnd, date) => {
        return date > dateEnd
    }
    const checkTestReportStatus = (item) => {
        let report_start_time = new Date(item.start_date);
        let report_end_time = new Date(item.end_date);
        let current_date = new Date();
        let test_report_status = '';
        if (checkInProgress(report_start_time, report_end_time, current_date)) {
            test_report_status = "In Progress"
        } else if (checkSchedult(report_start_time, current_date,)) {
            test_report_status = 'Scheduled'
        } else if (checkWillBeDeleted(report_end_time, current_date,)) {
            test_report_status = 'Done'
        }
        return test_report_status
    }

    useEffect(() => {

        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }
        getConfiguration()
    }, [])

    return (
        <div className="test_container">
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="test_right_part" >
                <div className="test_header">
                    <div className="test_first">
                        <Link to='/devices'>
                            <button>
                                <img src='../images/Fill-35.svg' alt='pictures' style={{ transform: 'rotate(180deg)' }} />
                                <p>Back</p>
                            </button>
                        </Link>
                        <h3>Test 544</h3>
                    </div>
                </div>
                <div className="test_right_name">
                    <div className="test_list">
                        <div className="list_name">
                            <h3>Test Results</h3>
                            <DeviceDetailsList nameFirst={'Status:'} nameSecond={checkTestReportStatus(data)} />
                            <DeviceDetailsList nameFirst={'Start:'} nameSecond={data.start_date ? data.start_date.split('T')[0] + ' ' + data.start_date.split('T')[1].split('.')[0] : ''} />
                            <DeviceDetailsList nameFirst={'End:'} nameSecond={data.end_date ? data.end_date.split('T')[0] + ' ' + data.end_date.split('T')[1].split('.')[0] : ''} />
                            <DeviceDetailsList nameFirst={'Undervoltage:'} nameSecond={data.voltage_min !== null ? data.voltage_min : 0} />
                            <DeviceDetailsList nameFirst={'Overvoltage:'} nameSecond={data.voltage_max !== null ? data.voltage_max : 0} />
                            <DeviceDetailsList nameFirst={'Power outages:'} nameSecond={data.power_outages ? data.power_outages : 0} />
                            <DeviceDetailsList nameFirst={'Impulse Surges:'} nameSecond={data.impulse_surges ? data.impulse_surges : 0} />
                            <DeviceDetailsList nameFirst={'Consuption:'} nameSecond={data.consumption ? data.consumption + ' ' + 'kWh' : 0} />
                            <DeviceDetailsList nameFirst={'Voltage:'} nameSecond={data.voltage_max !== null ? voltage : voltageNull} />
                            <DeviceDetailsList nameFirst={'Amperage:'} nameSecond={data.amperage_min !== null ? amperage : amperageNull} />
                            <DeviceDetailsList nameFirst={'Power:'} nameSecond={data.power_max !== null ? power : power_null} />
                        </div>
                        <div className="list_name">
                            <h3>Test Settings</h3>
                            <DeviceDetailsList nameFirst={'Load:'} nameSecond={data.power_on === null ? 'Enable' : 'Disable'} />
                            <DeviceDetailsList nameFirst={'Undervoltage Trigger:'} nameSecond={data.lower_voltage_trigger ? data.lower_voltage_trigger + ' ' + 'v' : 0} />
                            <DeviceDetailsList nameFirst={'Undervoltage Delay:'} nameSecond={data.undervoltage ? data.undervoltage + ' ' + 'sec' : 0} />
                            <DeviceDetailsList nameFirst={'Overvoltage Trigger:'} nameSecond={data.upper_voltage_trigger ? data.upper_voltage_trigger + ' ' + 'v' : 0} />
                            <DeviceDetailsList nameFirst={'Overvoltage Delay:'} nameSecond={data.uppervoltage ? data.uppervoltage + ' ' + 'sec' : 0} />
                            {/*<DeviceDetailsList nameFirst={'Power Restore Delay:'} nameSecond={data.upper_voltage_trigger ? data.upper_voltage_trigger + ' ' + 'v' : 0} />
                            <DeviceDetailsList nameFirst={'Startup Delay:'} nameSecond={data.startup_delay ? data.startup_delay + ' ' + 'sec' : 0} />*/}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

