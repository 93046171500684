import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock";
import './ClientsScreen.scss'
import ClientData from "../../Blocks/Clients/ClientsData";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";



export default function ClientScreen({ token, onLogout }) {
    const [data, setData] = useState([])
    const [valueUser, setValueUser] = useState('');
    const [limit, setLimit] = useState(20);
    const [itemId, setItemID] = useState([]);

    const getClients = (currentPage) => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/account/all?page=${currentPage}&count=${limit}&client=${valueUser}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result)
            })
    }

    useEffect(() => {
        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }
        const getComents = () => {
            let accesToken = localStorage.getItem('accesToken');

            let AuthStr = 'Bearer ' + accesToken
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': AuthStr
                }
            };
            fetch(`https://apiv1.zis.ru/account/all?page=1&count=${limit}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setData(result)
                    // console.log(result, 'sssssreeesss')
                })
        }
        getComents()
    }, [limit])


    const searchItems = (value, currentPage) => {

        setValueUser(value);

        let accesToken = localStorage.getItem('accesToken');
        let AuthStr = 'Bearer ' + accesToken;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };

        // console.log(value);

        fetch(`https://apiv1.zis.ru/account/all?page=1&count=${limit}&client=${value}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result)
            })

    }


    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        getClients(currentPage);
    };


    return (
        <div className="container__clientScreen">
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="clients_right" >
                <h3>Clients</h3>
                <div className="clients_inputDiv">
                    <p>Filter:</p>
                    <input placeholder={'Client'} value={valueUser} onInput={(e) => {  searchItems(e.target.value) }} />
                </div>
                <div className="clients_board">
                    <p>Client</p>
                    <p>Registred</p>
                    <p>Devices</p>
                    <p>Details</p>
                </div>
                <div className='inputs' >
                    {data.list?.map((value, index) => {
                        return (
                            <ClientData
                                key={index}
                                client={value.login}
                                register={value.cdate}
                                devices={value.count_devices}
                                details={'Details'}
                                id={value.id}
                                getItem={(e) => setItemID(e)}
                            />
                        )
                    })}
                </div>
                <ReactPaginate
                    previousLabel={null}
                    pageCount={data.pageQty}
                    nextLabel={null}
                    marginPagesDisplayed={5}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination_div'}
                    pageClassName={'pagination_div_item'}
                    pageLinkClassName={'pagination_div_item'}
                    previousClassName={'pagination_div_item_link'}
                    nextClassName={'pagination_div_item_link'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    )
}