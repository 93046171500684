import DeviceData from "../../Blocks/Devices/DevicesBlock/DeviceData"
import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock"
import './DevicesScreen.scss'
import React, { useState, useEffect } from "react"
import ReactPaginate from "react-paginate";



const DevicesScreen = ({ onLogout, token }) => {

    const [search, setSearch] = useState([])
    const [data, setData] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [valueId, setValueId] = useState('')
    const [valueIP, setValueIP] = useState('')
    const [valueUser, setValueUser] = useState('')
    const [valueName, setValueName] = useState('')
    const [limit, setLimit] = useState(20)


    const getClients = (currentPage) => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/devices?page=${currentPage}&count=${limit}&ip=${valueIP}&ident=${valueId}&owner=${valueUser}&name=${valueName}`, requestOptions)
            .then(response => response.json())
            .then(result => {
    
                setData(result)
            })
            .catch((error) => console.log('error', error))

    }

    const searchItems = (value, type) => {


        let ID = type == 'ID' ? value : valueId;
        let IP = type == 'IP' ? value : valueIP;
        let Client = type == 'Client' ? value : valueUser;
        let Device_Name = type == 'Device Name' ? value : valueName;



        setValueIP(IP)
        setValueId(ID)
        setValueName(Device_Name)
        setValueUser(Client)

        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/devices?page=1&count=13&ident=${ID}&ip=${IP}&owner=${Client}&name=${Device_Name}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result)
            })
            .catch((error) => console.log('error', error))
    }


    useEffect(() => {
        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }
        const getComents = () => {
            let accesToken = localStorage.getItem('accesToken')
            let AuthStr = 'Bearer ' + accesToken
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': AuthStr
                }
            };

            fetch(`https://apiv1.zis.ru/devices?page=1&count=${limit}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setData(result)
                    setPageCount(Math.ceil(data.length / 3))

                })
                .catch((error) => console.log('error', error))

        }

        getComents()
    }, [limit])


    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        getClients(currentPage);

    };

    return (
        <div className="container_devices">
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="devices_right">
                <h3>Devices</h3>
                <div className="devices_inputDiv">
                    <p>Filter:</p>

                    <input placeholder={'ID'} value={valueId} onChange={(e) => { searchItems(e.target.value, 'ID') }} />
                    <input placeholder={'IP'} value={valueIP} onInput={(e) => { searchItems(e.target.value, 'IP') }} />
                    <input placeholder={'Client'} value={valueUser} onChange={(e) => { searchItems(e.target.value, 'Client') }} />
                    <input placeholder={'Device Name'} value={valueName} onChange={(e) => { searchItems(e.target.value, 'Device Name') }} />

                </div>
                <div className="devices_board">
                    <p>ID</p>
                    <p>Type</p>
                    <p>Status</p>
                    <p>IP</p>
                    <p>Clinet</p>
                    <p>Details</p>
                    <p>Device Name</p>
                </div>
                <div>
                    {
                        data.list?.map((value, index) => {
                            return (
                                <DeviceData
                                    key={index}
                                    ident={value.ident}
                                    name={value.name}
                                    type={value.device_type}
                                    status={value.status === true ? 'Online' : 'Offline'}
                                    ip={value.last_ip}
                                    client={value.owner_login}
                                    details={'Details'}
                                    id={value.id}
                                    owner_id={value.owner_id}
                                />
                            )
                        })
                    }
                </div>

                <ReactPaginate
                    previousLabel={null}
                    pageCount={data.pageQty}
                    nextLabel={null}
                    marginPagesDisplayed={5}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination_div'}
                    pageClassName={'pagination_div_item'}
                    pageLinkClassName={'pagination_div_item'}
                    previousClassName={'pagination_div_item_link'}
                    nextClassName={'pagination_div_item_link'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    )
}

export default DevicesScreen