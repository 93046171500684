import './Popup.scss';

export default function EditUserPopup({ onClick, }) {
    return (
        <div className='popup_container' >
            <div className='white_block' >
                <h3>Your changes has successfully been saved!</h3>

                <button
                    onClick={onClick}
                >
                    Ok
                </button>


            </div>
        </div>
    )
}