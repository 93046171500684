import { useEffect } from 'react'
import { useState } from 'react'
import './SettingsInput.scss'

export default function SettingsInputs({ server, placeholderName, inputKey, type, keys, getSelected, valueInput,  }) {
    const [value, setValue] = useState(valueInput)
    const array = {'key':inputKey, 'value':value}


    return (
        <div className='inputDiv' >
            <p>{keys}</p>
            <input placeholder={placeholderName} key={inputKey} type={type} value={value}
                onChange={(e) => { 
                    setValue(e.target.value);
                    getSelected({'key':inputKey, 'value':e.target.value}) 
                }} 
             />
            <span>{server}</span>
        </div>
    )
}