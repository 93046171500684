import { useEffect } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import DeviceDetailsList from "../../../Blocks/Devices/DevicesBlock/DeviceDetails/DeviceDetailsList"
import DeviceDetailsListSecond from "../../../Blocks/Devices/DevicesBlock/DeviceDetails/DeviseDetailsListSecond"
import DevLists from "../../../Blocks/Devices/DevicesBlock/DeviceDetails/DevLists"
import NavbarBlock from "../../../Blocks/NavbarBlock/NavbarBlock"
import './DeviceDetailsScreen.scss'
import PilotTests from "../../../Blocks/Devices/DevicesBlock/DeviceDetails/PilotTests/PilotTests"
import ResetFactoryPopup from "../../Popup/ResetFactory"
import moment from "moment";


const DeviceDetailsScreen = ({ token, onLogout }) => {
    const pathName = window.location.href
    const id = pathName.split('/')
    const ides = id[id.length - 1]
    const [appState, setAppState] = useState({ loading: false })
    const [devData, setDevData] = useState([])
    const [resetFactoryPopup, setResetFactoryPopup] = useState(false)

    const getDevicesData = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/devices/full/${ides}`, requestOptions)
            .then(response => response.json())
            .then(result => { setDevData(result) })
            .catch(error => console.log(error, 'error'))
    }



    const resetFactory = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/devices/reset/${ides}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setResetFactoryPopup(false)
            })
            .catch(error => console.log(error, 'error'))
    }

    useEffect(() => {
        setAppState({ loading: true })

        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }
        getDevicesData()
        const interval = setInterval(() => {
            getDevicesData()
        }, [20000])
        return () => clearInterval(interval);
    }, [setAppState])


    let events = devData.events

    return (
        <div className="device_details_container">
            {resetFactoryPopup ? <ResetFactoryPopup onClick={resetFactory} /> : ''}
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="device_details_right">
                <div className="device_details_header">
                    <div className="first" >
                        <Link to='/devices'>
                            <button>
                                <img src='../images/Fill-35.svg' alt='pictures' style={{ transform: 'rotate(180deg)' }} />
                                <p>Back</p>
                            </button>
                        </Link>
                        <h3>Device {devData.ident}</h3>
                    </div>
                    <div className="second"  >
                        <p>Auto update every 20 sec</p>
                    </div>

                </div>
                <div className="device_details_name">
                    <div className="device_details_list">
                        <div className="nameFirst">
                            <h3>Device Details</h3>
                            <DeviceDetailsList nameFirst={'ID:'} nameSecond={devData.ident} />
                            <DeviceDetailsList nameFirst={'Device type:'} nameSecond={devData.device_type} />
                            <DeviceDetailsList nameFirst={'Registered:'} nameSecond={devData.cdate ? devData.cdate.split('T')[0] + ' ' + devData.cdate.split('T')[1].split('.')[0] : null} />
                            <DeviceDetailsList nameFirst={'Hardware version:'} nameSecond={devData.hw_version} />
                            <DeviceDetailsList nameFirst={'Software version:'} nameSecond={devData.sw_version} />
                            <DeviceDetailsList nameFirst={'Status:'} nameSecond={devData.status === true ? 'Online' : ''} />
                            <DeviceDetailsList nameFirst={'Last data:'} nameSecond={moment(devData.last_data).format('YYYY-MM-DD HH:mm:ss')} />
                            <DeviceDetailsList nameFirst={'IP:'} nameSecond={devData.last_ip} />
                            <DeviceDetailsList nameFirst={'Access Point:'} nameSecond={devData.wifi_name} />
                            <DeviceDetailsList nameFirst={'Voltage:'} nameSecond={devData.voltage + ' V'} />
                            <DeviceDetailsList nameFirst={'Amperage:'} nameSecond={devData.amperage + " A"} />
                            <DeviceDetailsList nameFirst={'Wattage:'} nameSecond={devData.consumption + ' W'} />

                            <button
                                onClick={() => setResetFactoryPopup(true)}
                            >
                                <p>Factory reset</p>
                            </button>
                        </div>
                        <div className="nameFirst" >
                            <h3>App Preferences</h3>
                            <DeviceDetailsList nameFirst={'Device name:'} nameSecond={devData.name} />
                            <DeviceDetailsList nameFirst={'Power:'} nameSecond={devData.active_power} />
                            <div style={{ marginTop: '25px', marginBottom: '25px' }} >
                                <DeviceDetailsList nameFirst={'Load Protection:'} nameSecond={devData.protection_is_on === true ? 'On' : 'Off'} />
                                <DeviceDetailsList nameFirst={'Protection Preset:'} nameSecond={devData.protection_preset} />
                                <DeviceDetailsList nameFirst={'Upper Trigger:'} nameSecond={devData.upper_voltage_trigger + ' V'} />
                                <DeviceDetailsList nameFirst={'Upper Delay:'} nameSecond={devData.upper_voltage_delay + ' ' + 'sec'} />
                                <DeviceDetailsList nameFirst={'Lower Trigger:'} nameSecond={devData.lower_voltage_trigger + " V"} />
                                <DeviceDetailsList nameFirst={'Lower Dealy:'} nameSecond={devData.lower_voltage_delay + ' ' + 'sec'} />
                                <DeviceDetailsList nameFirst={'Restore Delay:'} nameSecond={devData.power_restore_delay + ' ' + 'sec'} />
                                <DeviceDetailsList nameFirst={'Startup Delay:'} nameSecond={devData.startup_delay + " " + 'sec'} />
                                <DeviceDetailsList nameFirst={'Overcurrent Trigger:'} nameSecond={devData.amperage_trigger + " A"} />
                                <DeviceDetailsList nameFirst={'Overcurrent Dalay:'} nameSecond={devData.amperage_delay + ' ' + 'sec'} />
                            </div>


                            <DeviceDetailsList nameFirst={'Scheduler:'} nameSecond={devData.use_schedule === true ? 'On' : 'Off'} />
                            <DeviceDetailsList nameFirst={'Week days:'} nameSecond={devData.schedule_days?.split('')?.map(day => weekDaysData[day])?.join(' ')} />
                            <DeviceDetailsList nameFirst={'Start time:'} nameSecond={devData.schedule_time_on} />
                            <DeviceDetailsList nameFirst={'Stop time:'} nameSecond={devData.schedule_time_off} />
                        </div>

                    </div>
                    <h3>Client Details</h3>

                    <div className="device_details_lists">
                        <DeviceDetailsListSecond nameFirst={'Email:'} nameSecond={devData.owner_login} color='#004B84' textDecorationLine='underline' owner_id={devData.owner_id} email />
                        <DeviceDetailsListSecond nameFirst={'Devices:'} nameSecond={devData.owner_count_devices} color='black' textDecorationLine='none' details />
                        {devData.shared_to?.map((value, index) => {
                            return (
                                <DeviceDetailsListSecond key={index} nameFirst={'Shared with:'} nameSecond={value.login} color='#004B84' textDecorationLine='underline' emails idd={value.id} />
                            )
                        })}


                    </div>
                    <h3>Last Events (1 month)</h3>
                    <DevLists events={events} />
                    <h3>Pilot Tests</h3>
                    <PilotTests data={devData.tests} />
                </div>
            </div>
        </div>
    )
}

const weekDaysData = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun',
}

export default DeviceDetailsScreen
