import './ClientsData.scss'
import { Link } from 'react-router-dom'


const ClientData = ({ register, ip, client, details, devices, id, getItem }) => {

    const array = [id, devices]
    let registerData = register.split('T')
    let firstData = registerData[0]
    let secondData = registerData[1].split('.')[0]


    return (
        <div className="client_data">
            <p>{client}</p>
            <p>{firstData + ' ' + '     ' +  secondData}</p>
            <p style={{ textAlign: 'center', marginRight: '30px' }}>{devices}</p>
            <Link to={`/clients-details/${id}`}
                onClick={(e) => getItem(array)}
            >
                <p style={{ color: '#004B84', cursor: 'pointer', textDecoration: 'underline' }}>{details}</p>
            </Link>

        </div>
    )
}

export default ClientData