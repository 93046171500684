import { useState } from "react"
import { Link } from "react-router-dom"
import './AdminsList.scss'

export default function List({ id, login, name, isAdmin, isActive, details, data, getItem }) {
    const array = [id, login, name, isAdmin, isActive]

    return (
        <div className="listadmin">
            <p>{id}</p>
            <p>{login}</p>
            <p>{name}</p>
            <p>{isAdmin}</p>
            <p>{isActive}</p>
            <Link to={`/edit-user/${id}/${name}/${login}/${isAdmin}/${isActive}`}
                onClick={(e) => {
                    getItem(array)
                    localStorage.setItem('user_name', name)
                }}
            >
                <p>{details}</p>
            </Link>
        </div>
    )
}