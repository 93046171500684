import './DeviceData.scss'
import { Link } from 'react-router-dom'

const DeviceData = ({ ident, type, status, ip, client, details, name, id, owner_id }) => {

    return (
        <div className="dev_data">
            <p>{ident}</p>
            <p>{type}</p>
            <p>{status}</p>
            <p>{ip}</p>
            <Link to={`/clients-details/${owner_id}`}>
                <p style={{ color: '#004B84', cursor: 'pointer', textDecoration: 'underline' }}>{client}</p>
            </Link>
            <Link to={`/devices/${id}`}>
                <p style={{ color: '#004B84', cursor: 'pointer', textDecoration: 'underline' }}>{details}</p>
            </Link>
            <p>{name}</p>
        </div>
    )
}

export default DeviceData