import LoginScreen from "./components/AuthScreen/LoginScreen";
import './Style.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,

} from 'react-router-dom';
import DashboardScreen from "./components/Main/Dashboard/DashboardScreen";
import "@fontsource/montserrat";
import DevicesScreen from "./components/Main/Devices/DevicesScreen";
import DeviceDetailsScreen from "./components/Main/Devices/DeviceDetails/DeviceDetailsScreen";
import ClientScreen from "./components/Main/Clients/ClientsScreen";
import SettingsScreen from "./components/Main/Settings/SettingsScreen";
import ClientsDetails from "./components/Main/Clients/ClientsDetails/ClientsDetails";
import Admins from "./components/Main/Admins/Admins";
import AddNewUser from "./components/Main/AddNew/AddNewUser";
import EditUser from "./components/Main/EditUser/EditUser";
import { useState } from "react";
import CheckLogin from "./components/CheckLogin";
import TestScreen from "./components/Main/Test/TestScreen";
import ScrollToTop from "./components/Blocks/ScrollToTop/ScrollToTop";

function App() {

  const token2 = localStorage.getItem('accessToken');


  const [token, setToken] = useState(null)
  const handleLogin = async () => {
    let token = await localStorage.getItem('accesToken');
    setToken(token);
  };

  const handleLogout = () => {
    localStorage.removeItem('accesToken')
    setToken(null)
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<CheckLogin onLogout={handleLogout} onLogin={handleLogin} token={token} />} />
          <Route path="/devices" element={<DevicesScreen onLogout={handleLogout} token={token} />} />
          <Route path="/devices/:id" exact={true} element={<DeviceDetailsScreen token={token} onLogout={handleLogout} />} />
          <Route path="/clients" element={<ClientScreen token={token} onLogout={handleLogout} />} />
          <Route path="/settings" element={<SettingsScreen token={token} onLogout={handleLogout} />} />
          <Route path="/clients-details/:id" exact={true} element={<ClientsDetails token={token} onLogout={handleLogout} />} />
          <Route path="/admins" element={<Admins token={token} onLogout={handleLogout} />} />
          <Route path="/add-new-user" element={<AddNewUser token={token} onLogout={handleLogout} />} />
          <Route path="/edit-user/:id/:name/:login/:isAdmin/:isActive" exact={true} element={<EditUser onLogout={handleLogout} token={token} />} />
          <Route path='/test/:id' exact={true} element={<TestScreen onLogout={handleLogout} token={token} />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}


export default App;
