import NavbarBlock from '../../../Blocks/NavbarBlock/NavbarBlock'
import './ClientsDetails.scss'
import { Link } from 'react-router-dom'
import DeviceDetailsList from '../../../Blocks/Devices/DevicesBlock/DeviceDetails/DeviceDetailsList'
import AuthorizationData from '../../../Blocks/Clients/authorization/AuthorizationData'
import DevList from '../../../Blocks/Clients/devList/DevList'
import { useEffect } from 'react'
import { useState } from 'react'
import NewPasswordPopup from '../../Popup/NewPasswordPopup'
import Popup from '../../Popup/Popup'
import EditUserPopupNone from '../../Popup/EditUserPopupNone'


export default function ClientsDetails({ token, onLogout }) {
    const pathName = window.location.href
    const id = pathName.split('/')
    const ides = id[id.length - 1]
    const [appState, setAppState] = useState({ loading: false })
    const [dataClients, setDataClients] = useState([])
    const [open_new_pass_popup, setOpenNewPassPopup] = useState(false)
    const [open_delete_popup, setOpenDeletePopup] = useState(false)
    const [open_wrong, setOpenWrong] = useState(false)



    const getClientsData = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/account/full/${ides}`, requestOptions)
            .then(response => response.json())
            .then(result => { setDataClients(result); })
    }


    const resetPassword = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/account/reset_password/${ides}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.result === 'success') {
                    setOpenNewPassPopup(true)
                } else {
                    setOpenWrong(true)
                }
            })
    }


    const deleteClient = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch(`https://apiv1.zis.ru/account/${ides}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result, 'delete')
                if (result.result === 'success') {
                    window.location = '/clients'
                }
            })
    }

    useEffect(() => {
        setAppState({ loading: true })

        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }

        getClientsData()
    }, [setAppState])

    const closeNewPassPopup = () => {
        setOpenNewPassPopup(!open_new_pass_popup)
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(!open_delete_popup)
    }

    const closeWrongPopup = () => {
        setOpenWrong(!open_wrong)
    }

    let admin = localStorage.getItem('isAdmin')




    return (
        <div className="container_clientDetails" >
            {open_new_pass_popup ? <NewPasswordPopup onClick={closeNewPassPopup} /> : ''}
            {open_delete_popup ? <Popup onClick={closeDeletePopup} deleteUser={deleteClient} /> : ''}
            {open_wrong ? <EditUserPopupNone onClick={closeWrongPopup} /> : ''}
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className='clientDetails_right'>
                <div className="client_details_header">
                    <Link to='/clients'>
                        <button>
                            <img src='../images/Fill-35.svg' alt='pictures' style={{ transform: 'rotate(180deg)' }} />
                            <p>Back</p>
                        </button>
                    </Link>

                    <h3>Client {dataClients.login}</h3>
                </div>
                <div className='clients_details_name'>
                    <h3>Client Details</h3>
                    <div className='clents_details_list'>
                        <DeviceDetailsList nameFirst={'Email:'} nameSecond={dataClients.login} />
                        <DeviceDetailsList nameFirst={'Registred:'} nameSecond={dataClients.cdate ? dataClients.cdate.split('T')[0] + ' ' + dataClients.cdate.split('T')[1].split('.')[0] : null} />
                    </div>
                    <div className='clients_details_buttonsDiv'>
                        <button
                            onClick={() => resetPassword()}
                        >
                            <p>Email reset password</p>
                        </button>
                        {admin === true ?
                            <button
                                onClick={() => setOpenDeletePopup(true)}
                            >
                                <p>Delete account</p>
                            </button>
                            :
                            <div></div>
                        }

                    </div>
                    <h3>Authorizations</h3>
                    <AuthorizationData data={dataClients} />
                    <h3>Devices</h3>
                    <DevList devData={dataClients} />
                </div>
            </div>
        </div>
    )
}