import './DeviseDetailsList.scss'

const DeviceDetailsList = ({ nameFirst, nameSecond }) => {
    return (
        <div className='devList' >
            <p>{nameFirst}</p>
            <div>
                <span>{nameSecond}</span>
            </div>
        </div>
    )
}

export default DeviceDetailsList