import TestList from "./TestList";
import './PilotTests.scss'

export default function PilotTests({ data }) {

    const checkInProgress = (dateStart, dateEnd, date) => {
        return date > dateStart && date < dateEnd;
    }

    const checkSchedult = (dateStart, date) => {
        return date < dateStart
    }

    const checkWillBeDeleted = (dateEnd, date) => {
        return date > dateEnd
    }
    const checkTestReportStatus = (item) => {

        let report_start_time = new Date(item.start_date);
        let report_end_time = new Date(item.end_date);
        let current_date = new Date();

        let test_report_status = '';
        if (checkInProgress(report_start_time, report_end_time, current_date)) {
            test_report_status = "In Progress"
        } else if (checkSchedult(report_start_time, current_date,)) {
            test_report_status = 'Scheduled'
        } else if (checkWillBeDeleted(report_end_time, current_date)) {
            test_report_status = 'Done'
        }
        return test_report_status
    }


    return (
        <div className='pilot_lists'>
            <div className='pilot_lists_header' >
                <p> ID </p>
                <p> Start </p>
                <p> End </p>
                <p> Status </p>
                <p> Details</p>
            </div>
            <div>
                {data?.map((value, index) => {
                    return (
                        <TestList id={value.id} key={index} start={value.start_date ? value.start_date.split('T')[0] + ' ' + value.start_date.split('T')[1].split('.')[0] : null} end={value.end_date ? value.end_date.split('T')[0] + ' ' + value.end_date.split('T')[1].split('.')[0] : null} status={checkTestReportStatus(value)} details={'Details'} />
                    )
                })}
            </div>
        </div>
    )
}
