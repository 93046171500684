import { Link } from 'react-router-dom'
import './DevList.scss'


export default function SmallList({ ident, id, type, status, ip, details }) {

    return (
        <div className="smallList">
            <p>{ident}</p>
            <p>{type}</p>
            <p>{status}</p>
            <p>{ip}</p>
            <Link to={`/devices/${id}`}>
                <p>{details}</p>
            </Link>

        </div>
    )
}
