import { Link } from "react-router-dom";

export default function TestList({ id, start, end, status, details }) {
    return (
        <div className="testList">
            <p>{id}</p>
            <p>{start}</p>
            <p>{end}</p>
            <p>{status}</p>
            <Link to={`/test/${id}`} >
                <p>{details}</p>
            </Link>

        </div>
    )
}