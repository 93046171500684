
import LoginScreen from "./AuthScreen/LoginScreen"
import DashboardScreen from "./Main/Dashboard/DashboardScreen"

const CheckLogin = ({ token, onLogin, onLogout }) => {
    const token2 = localStorage.getItem('accesToken')
    if (token2) {
        return <DashboardScreen token={token} onLogout={onLogout} />

    } else {
        return <LoginScreen onLogin={onLogin} />
    }


}
export default CheckLogin