import './Popup.scss';

export default function ResetFactoryPopup({ onClick, }) {
    return (
        <div className='popup_container' >
            <div className='white_block' >
                <h3>Are you sure you want to reset your device settings?</h3>

                <button
                    onClick={onClick}
                >
                    Reset
                </button>


            </div>
        </div>
    )
}