import './AdminsList.scss'
import List from './List'

export default function Dashboard({ data }) {
    
    return (
        <div className='admins_list'>
            <div className='admins_list_header' >
                <p>ID </p>
                <p>Login </p>
                <p>Name </p>
                <p>Is Admin </p>
                <p>Is Active</p>
                <p>Details</p>
            </div>
            <div>
                {
                    data.map((value, index) => {
                        return (
                            <List
                                key={index}
                                id={value.id}
                                login={value.login}
                                name={value.name}
                                isAdmin={value.is_admin === true ? 'Yes' : 'No'}
                                isActive={value.is_active === true ? 'Yes' : 'No'}
                                details={'Details'}
                                getItem={(e) =>{}}
                            />
                        )
                    })
                }

            </div>

        </div>
    )
}