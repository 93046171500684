import { useState } from 'react'
import { Link } from 'react-router-dom'
import './DeviseDetailsList.scss'

const DeviceDetailsListSecond = ({ nameFirst, nameSecond, color, textDecorationLine, email, details, owner_id, idd, emails }) => {
    const [id, setId] = useState('')

    return (
        <div className='devListSecond' >
            <p>{nameFirst}</p>

            <div style={{ color: color, textDecorationLine: textDecorationLine }}>
                {email &&
                    <Link to={`/clients-details/${owner_id}`} ><span>{nameSecond}</span></Link>
                }
                {details && 
                <span>{nameSecond}</span>
                }
               {emails && 
                <Link to={`/clients-details/${idd}`} onClick={(e) => setId(idd)} ><span>{nameSecond}</span></Link>
               }

            </div>
        </div>
    )
}

export default DeviceDetailsListSecond