import './Popup.scss';

export default function Popup({ onClick, deleteUser }) {
    return (
        <div className='popup_container' >
            <div className='white_block' >
                <h3>Are you sure you want to delete your account?</h3>
                <div>
                    <button
                        onClick={onClick}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={deleteUser}
                    >
                        Accept
                    </button>

                </div>

            </div>
        </div>
    )
}