import './Auth.scss'

export default function AuthList({ ip, lastRequest, authorization, platform, notification }) {
    return (
        <div className="list">
            <p>{ip}</p>
            <p>{authorization}</p>
            <p>{lastRequest}</p>
            <p>{platform}</p>
            <p>{notification}</p>
        </div>
    )
}