import {Link, useLocation} from 'react-router-dom'
import './NavbarBlock.scss'
import { useState } from 'react';
import { useEffect } from 'react';


const NavbarBlock = ({ token, onLogout }) => {
    const {pathname} = useLocation()
    let admin = localStorage.getItem('isAdmin')
    const [error, setError] = useState('')
    const [data, setData] = useState([])
    const [selected, setSelected] = useState([])

    const logOut = async () => {
        setError("")
        try {
            await onLogout()
            window.location.href = "/";
        } catch {
            setError("Failed to log out")
        }
    }

    const getConfigs = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch('https://apiv1.zis.ru/configuration', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    return onLogout();
                }
                return response.json()
            })
            .then(result => {

                setData(result)
                let selected_new_data = []
                let key = ''
                let value = ''
                for (let i = 0; i <= result.length; i++) {
                    if (result[i]?.key === 'manuals_url') {
                        key = result[i].key
                        value = result[i].value
                        selected_new_data.push({ key: key, value: value })

                    }
                }
                setSelected(value)

            })
            .catch(e => {
                if (e.status === 401) {
                    onLogout()
                }
            })
    }


    useEffect(() => {
        getConfigs()
    }, [])

    return (
        <div className="navbarDiv">
            <Link to='/'>
                <svg width="155" height="52" viewBox="0 0 155 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_42_491)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M149.484 46.8324L146.408 44.8945C145.598 46.6709 144.14 47.8013 142.197 47.4783C140.253 47.1553 140.577 46.0249 140.739 44.0871L146.893 22.9318H153.696L154.829 19.056H148.027L152.076 4.68336L138.472 9.2051L135.556 19.056H130.536L129.402 22.9318H134.423L128.754 43.4411C127.621 47.1553 128.431 52.0001 136.366 52.0001C142.197 52.0001 146.408 51.0312 149.484 46.8324ZM59.5996 11.7889C60.0854 14.3727 63.8104 15.8261 67.5353 14.8572C71.2603 13.7268 73.528 10.82 72.8797 8.39758C72.0701 5.81376 68.6691 4.36034 64.9441 5.32928C61.2192 6.29823 58.6278 9.20503 59.5996 11.7889ZM103.327 48.6087C97.9832 48.6087 96.2014 41.1802 98.793 33.9131C101.06 26.8075 107.214 21.4783 111.749 21.4783C117.741 21.1553 117.904 30.1988 115.636 36.174C113.693 41.8261 109.967 48.6087 103.327 48.6087ZM128.917 32.4597C129.402 23.4163 123.247 18.0871 111.749 18.5715C103.489 18.8945 95.3917 22.6087 90.8568 28.2609C87.78 32.2982 85.9982 37.6274 87.1318 42.4721C88.5898 48.1243 93.9337 51.8386 104.137 51.8386C115.636 51.6771 127.782 44.2485 128.917 32.4597ZM53.6072 22.9318H56.5225L49.7203 46.9939H43.566L42.4324 50.8697H85.6745L86.808 46.9939H80.6536L93.1246 4.36035H75.309L74.1755 8.23609H79.5201L68.831 46.8324H62.1909L69.9647 18.8945H55.2268L53.6072 22.9318Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M44.6995 17.6026C43.4038 22.4473 40.6506 25.1926 37.5735 26.969C33.5246 29.3914 28.8279 29.3914 26.0747 29.0684L31.9051 8.23609C34.1724 7.91311 38.8691 7.91311 41.9463 9.85105C44.3756 11.3045 45.8332 13.5653 44.6995 17.6026ZM18.9488 8.39765L8.09774 46.8324H1.13369L0 50.7082H26.8845L28.0182 46.8324H21.2161L25.103 32.7827C34.6584 32.9442 41.1366 31.8138 45.9952 29.7144C50.6919 27.615 53.4452 24.8697 55.0647 21.8013C56.0364 20.0249 56.6842 17.9256 57.0081 15.6647C57.1701 13.8883 57.0081 12.2734 56.1984 10.82C54.5788 8.0746 52.3114 6.45973 49.2343 5.65228C45.5094 4.52184 40.8127 4.36035 35.4681 4.36035H12.9564L11.8227 8.39765H18.9488Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M112.235 7.59005C112.235 3.3913 108.834 0 104.623 0C100.413 0 97.0112 3.3913 97.0112 7.59005C97.0112 11.7888 100.413 15.1801 104.623 15.1801C108.834 15.1801 112.235 11.7888 112.235 7.59005ZM110.939 7.59005C110.939 11.1428 108.024 13.8882 104.623 13.8882C101.06 13.8882 98.307 10.9813 98.307 7.59005C98.307 4.03723 101.06 1.29189 104.623 1.29189C108.186 1.29189 110.939 4.03723 110.939 7.59005ZM102.68 4.68321H104.785C105.595 4.68321 106.242 5.16768 106.242 5.81364C106.242 6.62109 105.595 7.10557 104.623 7.10557H102.68V4.68321ZM101.384 11.7888H102.68V8.23601H103.489C104.461 8.23601 104.947 8.55898 105.919 10.3354L106.567 11.6273H108.186L107.214 10.0124C106.567 8.88196 106.081 8.23601 105.271 7.91303C106.728 7.91303 107.7 7.10557 107.7 5.81365C107.7 4.8447 107.052 3.71427 104.947 3.71427H101.384V11.7888Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_42_491">
                            <rect width="155" height="52" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Link>

            <div className='navbarDiv_input'>
                {admin === 'true' ?
                    <p>Admin</p>
                    :
                    <p>Manager</p>
                }

            </div>
            <div className='container_linkDiv'>
                <ul>
                    <li className={pathname === '/' && 'active'}>
                        <Link to='/'>
                            <p>Dashboard</p>
                        </Link>
                    </li>
                    <li className={pathname === '/devices' && 'active'}>
                        <Link to='/devices'>
                            <p>Devices</p>
                        </Link>
                    </li>
                    <li className={pathname === '/clients' && 'active'}>
                        <Link to='/clients'>
                            <p>Clients</p>
                        </Link>
                    </li>
                    {admin === 'true' ?
                        <li className={pathname === '/settings' && 'active'}>
                            <Link to='/settings'>
                                <p>Settings</p>
                            </Link>
                        </li>
                        :
                        ('')
                    }

                    {admin === 'true' ?
                        (
                            <li className={pathname === '/admins' && 'active'}>
                            <Link to='/admins'>
                                <p>Admins</p>
                            </Link>
                        </li>)
                        :
                        ('')
                    }

                    <li>

                        <a href={`${selected}`}>
                            <p>Manuals</p>
                        </a>


                    </li>
                    <li>
                        <Link
                        target={'_blank'}
                            onClick={() => { logOut() }}
                        >
                            <p>Logout</p>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="navbar_logo">
                <svg width="101" height="13" viewBox="0 0 101 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_42_474)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.6042 6.43186C55.6042 5.23093 55.779 4.4695 56.0259 3.73283C56.3933 2.5876 57.7786 1.49188 58.9712 1.49807C60.2603 1.49807 61.1393 2.03664 61.7898 3.00855C62.3982 3.86283 62.6693 5.16903 62.6693 6.51234C62.6693 7.61425 62.296 9.11853 61.6154 10.0781C60.9349 11.0004 60.1455 11.3657 59.0073 11.3657C57.4654 11.3657 56.6462 10.27 56.2668 9.61377C55.6404 8.46853 55.6042 7.0571 55.6042 6.43186ZM58.158 6.62996C58.158 7.29234 58.3267 8.96996 59.194 8.96996C60.0071 8.96996 60.1395 7.0571 60.1395 6.55567C60.1395 4.85331 59.8565 3.92474 59.1277 3.92474C58.3989 3.91855 58.158 5.08235 58.158 6.62996Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M73.6494 11.2173L73.6554 1.69629H76.1365C77.1249 1.69629 78.468 1.93153 79.1605 2.79819C79.8411 3.67105 79.817 4.77915 79.817 5.262C79.817 5.73867 79.5158 6.65489 79.0156 7.2368C78.5161 7.80013 77.6666 8.1406 76.4257 8.1406C76.3596 8.1406 76.2634 8.10346 76.1305 8.10346V11.2173H73.6494ZM76.1365 5.96153C76.781 5.96153 77.2812 5.58391 77.2812 4.90296C77.2812 4.22819 76.769 3.73915 76.1365 3.68343V5.96153Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M86.358 11.2419L83.72 11.2172L83.5811 9.96668H81.8768L81.6904 11.2172H79.1245L81.311 1.70239H84.3525L86.358 11.2419ZM82.2261 7.98573H83.2986L82.9493 5.46001L82.7984 3.98668L82.618 5.46001L82.2261 7.98573Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M98.8255 11.2358H96.3077L96.3137 7.18727L94.0129 1.69629L96.6389 1.71486L97.1752 3.572L97.5065 5.15677L97.8257 3.57819L98.38 1.71486L101 1.72105L98.8375 7.21203L98.8255 11.2358Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M87.0386 11.2233L87.0566 1.7085H89.4175L91.4471 7.44087L91.1886 4.62421V1.7085L93.5735 1.71469L93.5675 11.2294L91.1706 11.2542L89.2612 5.58993L89.4475 8.0042L89.4415 11.2233H87.0386Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M72.7578 11.2172L70.1318 11.2111L69.9328 7.54629L69.9028 5.18154L69.4814 7.54629L68.8489 11.2111L66.8193 11.2049L66.271 7.55248L65.9277 5.21869L65.8796 7.55248L65.6746 11.2049H63.0186L64.0851 1.69012H67.1746L67.6261 4.85964L67.8972 7.00772L68.1924 4.86583L68.7587 1.67773H71.8362L72.7578 11.2172Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M54.9058 8.49945V11.0004C54.43 11.2356 54.0265 11.3409 53.4844 11.3409C52.8279 11.3409 52.0991 11.1552 51.7437 10.988C50.6716 10.4804 50.4428 9.94183 50.1235 9.29183C49.7802 8.5985 49.5514 8.09088 49.5454 6.53707C49.5212 4.98327 50.0452 3.55946 50.6295 2.85374C51.027 2.37708 52.2136 1.5166 53.4061 1.5166C53.8097 1.5166 54.4662 1.59089 54.9058 1.78898L54.8998 4.60565C54.689 4.16613 54.177 3.89374 53.647 3.89374C52.5508 3.89374 52.063 5.20613 52.0871 6.38231C52.0991 7.54612 52.3701 8.98231 53.6772 8.98231C54.1048 8.97612 54.5203 8.73469 54.9058 8.49945Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.4323 7.99805C40.7756 8.56757 41.4803 9.03186 41.9682 9.03186C42.5524 9.03186 42.7813 8.72853 42.7813 8.36948C42.7813 7.75662 40.9443 6.69805 40.6612 5.98618C40.5467 5.69522 40.3661 5.26189 40.3661 4.66761C40.3661 3.62142 41.0226 2.16665 41.9561 1.80142C42.7271 1.49189 43.8233 1.26284 45.0399 1.99951L45.0279 4.84094C44.5702 4.1538 44.022 3.83808 43.4981 3.83808C43.1908 3.83808 42.9198 4.07332 42.9198 4.31475C42.9198 4.54999 43.0704 4.87808 43.2511 5.03903C43.5041 5.30523 44.54 5.99856 44.7629 6.42567C44.9436 6.77853 45.2748 7.40377 45.2748 8.20234C45.2748 9.40329 44.7869 10.0657 44.6183 10.2328C44.1425 10.7838 43.5583 11.3347 42.2212 11.3347C41.8839 11.3347 41.125 11.2481 40.4323 10.9509V7.99805Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.6922 1.66528H39.1616L39.1555 11.18H36.6862L36.6922 1.66528Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1514 11.1739L32.3556 3.86292L31.2835 3.91244H29.6513V1.64673L35.8128 1.6653L32.7049 9.05671L33.759 8.95766L35.4815 8.96385V11.18L29.1514 11.1739Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.852 2.37085H20.4421C18.8822 1.65894 16.9127 1.15751 14.7685 0.928467C14.7866 0.996562 14.7926 1.06466 14.7926 1.13894C14.7926 1.26894 14.7624 1.41751 14.6902 1.54751C16.1778 1.67132 17.6595 1.98085 18.852 2.37085Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1605 2.2781C13.3832 2.2781 14.371 1.76429 14.371 1.13905C14.377 0.507619 13.3892 0 12.1665 0C10.9499 0 9.95605 0.507619 9.95605 1.13286C9.95605 1.76429 10.9499 2.2781 12.1605 2.2781Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.6491 1.54141C9.58285 1.4176 9.54065 1.26903 9.54065 1.13284C9.54065 1.05855 9.55273 0.984268 9.57077 0.922363C7.41454 1.14522 5.43899 1.64665 3.87903 2.35855H5.48115C6.68578 1.96855 8.14937 1.67141 9.6491 1.54141Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5741 4.6552C22.9357 3.81948 22.0262 3.19425 21.0866 2.68663L15.4069 2.68044C14.6419 2.68044 14.4131 3.36139 14.7624 3.89996L17.6234 8.44997L13.9854 8.44378L13.9914 2.66187H10.3656L10.3596 8.44378H6.73977L9.60071 3.89996C9.95 3.36139 9.72113 2.68044 8.96225 2.68663L3.21027 2.67425C2.69831 2.97758 2.13817 3.31187 1.6744 3.68948C0.614351 4.56853 0 5.58377 0 6.66711C0 9.90473 5.45083 12.5604 12.1544 12.5666C18.8521 12.5728 24.3209 9.9233 24.327 6.69187C24.327 5.96758 24.0559 5.28044 23.5741 4.6552ZM23.0923 8.0352C22.4177 8.87711 21.1468 9.58282 19.4724 10.0843C17.5089 10.6847 15.0214 11.0685 12.1544 11.0685C8.16723 11.0623 4.72807 10.3133 2.6682 9.16187C2.0659 8.8214 1.57201 8.46235 1.2287 8.0352C0.879361 7.60187 0.722763 7.01997 0.69265 6.55568C0.69265 5.41663 1.38529 4.42615 2.79468 3.55948L6.17964 3.56567L3.45721 7.89901C3.02356 8.58616 3.42107 9.32282 4.58954 9.32901C9.63684 9.33521 14.6901 9.3414 19.7314 9.34759C20.8998 9.35997 21.3396 8.59854 20.9059 7.9114L18.1955 3.56567L21.5263 3.57187C22.9176 4.45091 23.6404 5.50329 23.6404 6.58044C23.6404 7.10663 23.4476 7.58949 23.0923 8.0352Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.809 10.4062C23.1525 10.4062 22.6105 10.9634 22.6105 11.6258C22.6105 12.3005 23.1525 12.8577 23.809 12.8577C24.4535 12.8577 24.9956 12.3005 24.9956 11.6258C24.9956 10.9634 24.4535 10.4062 23.809 10.4062ZM23.809 12.7153C23.2127 12.7153 22.749 12.2324 22.749 11.6258C22.749 11.0253 23.2188 10.5486 23.809 10.5486C24.3932 10.5486 24.8571 11.0315 24.8571 11.6258C24.8571 12.2386 24.3872 12.7153 23.809 12.7153Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3028 12.0095C24.3028 11.8671 24.2908 11.7247 24.0861 11.669V11.6442C24.2487 11.6195 24.3511 11.4647 24.3511 11.2914C24.3511 10.9261 24.0258 10.9014 23.8692 10.9014H23.2368V12.3561H23.4416V11.7433H23.8451C24.0861 11.7433 24.1101 11.9104 24.1101 12.0404C24.1101 12.2323 24.1101 12.288 24.1463 12.3499H24.3631C24.3028 12.2695 24.3149 12.2137 24.3028 12.0095ZM23.8692 11.5947H23.4536V11.0747H23.8451C24.0198 11.0747 24.1463 11.1428 24.1463 11.3347C24.1463 11.5699 23.9596 11.6009 23.8692 11.5947Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_42_474">
                            <rect width="101" height="13" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default NavbarBlock
