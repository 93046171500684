import DeviseDataBoardList from './DeviceDataBoardList'
import './DevLists.scss'

export default function DevLists({ events }) {

    return (
        <div className='devs_lists'>
            <div className='devs_lists_header' >
                <p>Date </p>
                <p>EventCode</p>
                <p>Description </p>
            </div>
            <div>
                {
                    events?.map((value, index) => <DeviseDataBoardList
                        key={index}
                        secondText={value.event_code}
                        firstText={value.event_time.split('T')[0] + ' ' + value.event_time.split('T')[1].split('.')[0] }
                        thirdText={descriptionData[value.event_code]}
                    />)
                }

            </div>

        </div>
    )
}

const descriptionData = {
    start: 'Включение нагрузки при старте розетки',
    powerSag: 'Пропадание питания',
    overvoltage: 'Сработала защита по перенапряжению',
    undervoltage: 'Сработала защита по пониженному напряжению',
    overcurrent: 'Сработала защита по превышению тока',
    autorecovery: 'Повторное включение после отключения',
    testStarted: 'Начался режим тест',
    testFinished: 'Завершился режим тест',
    testAborted: 'Режим тест прерван',
    consumpMeasStarted: 'Началось измерение энергопотребления',
    consumpMeasFinished: 'Закончилось измерение энергопотребления',
}
