import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock";
import SettingsInputs from "../../Blocks/Settings/SettingsInput";
import React, { useState, useEffect } from "react";
import './SettingsScreen.scss'
import UpDatePopup from "../Popup/UpdatePopup";
import EditUserPopupNone from "../Popup/EditUserPopupNone";


export default function SettingsScreen({ onLogout, token }) {
    const [appState, setAppState] = useState({ loading: false })
    const [data, setData] = useState([])
    const [selected, setSelected] = useState([])
    const [click, setClick] = useState(false)
    const [updatePopup, setOpenUpdatePopup] = useState(false)
    const [open_wrong, setOpenWrong] = useState(false)


    const createFullKeys = (changed_input) => {

        let selected_new_data = [];
        for (const [item_index, item] of data.entries()) {
            if (item.key == changed_input.key) {
                data[item_index].value = changed_input.value
            }

            selected_new_data.push({ key: item.key, value: item.value })
        }

        setSelected(selected_new_data);

    }

    const getConfigs = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch('https://apiv1.zis.ru/configuration', requestOptions)
            .then(response => response.json())
            .then(result => {

                setData(result)
            })
    }



    const patchConfigs = () => {
        setClick(true)
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken;
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            },
            body: JSON.stringify(selected)
        };
        fetch('https://apiv1.zis.ru/configuration', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.result == 'success') {
                    setOpenUpdatePopup(true)
                }
                else {
                    setOpenWrong(true)
                }

            })
    }


    useEffect(() => {
        setAppState({ loading: true });

        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {
            window.location.href = '/'
            return false;
        }

        getConfigs()
    }, [setAppState])


    const closeUpdatePopup = () => {
        setOpenUpdatePopup(!updatePopup)
    }

    const closeWrongPopup = () => {
        setOpenWrong(!open_wrong)
    }

    return (
        <div className="settings_container" >
            {updatePopup ? <UpDatePopup onClick={closeUpdatePopup} /> : ''}
            {open_wrong ? <EditUserPopupNone onClick={closeWrongPopup} /> : ''}
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="settings_right" >
                <h3>Settings</h3>
                <div className="settings_input" >
                    {data.map((value, index) => {
                        return (
                            <SettingsInputs
                                key={index}
                                server={value.description}
                                valueInput={value.value}
                                inputKey={value.key}
                                type={value.key === 'smtp_password' ? 'password' : ''}
                                value={value}
                                keys={value.key}
                                getSelected={(e) => createFullKeys(e)} />
                        )
                    })}
                </div>
                <div className="buttonsDiv">
                    <button
                        onClick={() => { patchConfigs(); setClick(!click) }}
                    >
                        <p>Update settings</p>
                    </button>
                    {
                        click === true && selected[1] === '' ? <p>*at least one field is required </p> : null
                    }
                </div>
            </div>
        </div>
    )
}
