import React, { useState } from 'react'
import './LoginStyle.scss'
import md5 from 'md5'
import { useNavigate } from "react-router-dom";


const LoginScreen = ({ onLogin }) => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    let hash_password = login.toLowerCase() + password
    let hash_password_result = md5(hash_password)
    let navigate = useNavigate();
    const [data, setData] = useState('')


    const loginFunction = () => {

        let raw = JSON.stringify({
            "login": login,
            "password": hash_password_result
        });
        const requestOptions = {
            method: 'post',
            body: raw,
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        fetch('https://apiv1.zis.ru/users/login', requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result)
         
                if (!result.error) {

                    localStorage.setItem('accesToken', result.accessToken)
                    localStorage.setItem('isAdmin', result.user.is_admin)
                    onLogin()
                    window.location.href('/')
                }

            })
            .catch(error => console.log(error, 'error'))
    }


   

    return (
        
        <div className='container' >
            
            <div className='container_login'>
                <img src='../images/pilotLogo.png' alt='pictures' />
                <p>Devices dashboard</p>
                <div className='container_inputDiv'>
                    <input
                        value={login}
                        onChange={e => setLogin(e.target.value)}
                        placeholder='Login'
                        type={'email'}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                loginFunction()
                            }
                        }}
                        style={data.error === 'validate_parameters' ? { marginBottom: '0' } : { marginBottom: '15px' }}
                    />
                    {data.error === 'validate_parameters' ? (
                        <p style={{ marginTop: '10px' }} >Incorrect email</p>
                    )
                        :
                        null
                    }

                    <div>
                        <input
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder='Password'
                            type="password"
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    loginFunction()
                                }
                            }}
                        />

                        <button
                            onClick={() => { loginFunction() }}
                        >
                            <img src='../images/Fill-35.svg' alt='pictures' />
                        </button>
                    </div>
                    {
                        data.error === 'invalid_password' ?
                            <p style={{ marginTop: '10px' }}> Invalid Password</p>
                            :
                            null
                    }

                </div>
            </div>
            <img className='image' src='../images/zislogo.png' alt='pictures' />
        </div>
    )
}
export default LoginScreen