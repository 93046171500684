import { useEffect, useState } from "react";
import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock";
import './AddNewUser.scss'
import md5 from 'md5'
import AddUserPopup from "../Popup/AddUserSuccess";
import EditUserPopupNone from '../Popup/EditUserPopupNone'
import {useNavigate} from "react-router-dom";

export default function AddNewUser({ token, onLogout }) {
    const navigate = useNavigate();
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [login, setLogin] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    let hash_password = login.toLowerCase() + password
    let hash_password_result = md5(hash_password)
    const [addUserPopup, setAddUserPopup] = useState(false)
    const [wrongPopup, setWrongPopup] = useState(false)

    const addNewUser = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        let raw = JSON.stringify({
            "login": login,
            "password": hash_password_result,
            "name": name,
            "is_active": isActive,
            "is_admin": isAdmin
        });
        const requestOptions = {
            method: 'post',
            body: raw,
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        };
        fetch('https://apiv1.zis.ru/users', requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.result === 'success') {
                    setAddUserPopup(true)
                }
                else {
                    setWrongPopup(true)
                }


            })
            .catch(error => console.log(error, 'error'))
    }

    useEffect(() => {
        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }
    }, [])

    const closeAddUserPopup = () => {
        setAddUserPopup(!addUserPopup)
        navigate('/admins')
    }

    const closeWrongPopup = () => {
        setWrongPopup(!wrongPopup)
    }

    return (
        <div className="addNew_admins"  >
            {addUserPopup ? <AddUserPopup onClick={closeAddUserPopup} /> : ''}
            {wrongPopup ? <EditUserPopupNone onClick={closeWrongPopup} /> : ''}
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="container_addnew_rightPart">
                <h3>Add New User</h3>
                <div className="inputDiv">
                    <p>Name:</p>
                    <input placeholder="Value" type={''} value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className="inputDiv">
                    <p>Login:</p>
                    <input placeholder="Value" type={''} value={login} onChange={e => setLogin(e.target.value)} />
                </div>
                <div className="inputDiv">
                    <p>Password:</p>
                    <input placeholder="Value" type={'password'} value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="inputDiv">
                    <p>Is Admin:</p>
                    <label className="container">
                        <input type="checkbox"
                            onChange={(e) => {
                                e.target.checked ? setIsAdmin(true) : setIsAdmin(false)
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="inputDiv">
                    <p>Is Active:</p>
                    <label className="container">
                        <input type="checkbox"
                            onChange={(e) => {
                                e.target.checked ? setIsActive(true) : setIsActive(false)
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <button
                    onClick={() => addNewUser()}
                >
                    <p>Save</p>
                </button>
            </div>
        </div>
    )
}
