import './DevList.scss'
import SmallList from './SmallList'

export default function DevList({ devData }) {


    return (
        <div className='devs_list'>
            <div className='devs_list_header' >
                <p>ID </p>
                <p>Type</p>
                <p>Status </p>
                <p>IP</p>
                <p>Details</p>
            </div>
            <div>
                {
                    devData.devices?.map((value, index) => {
                        return (
                            <SmallList key={index} ident={value.ident} type={value.device_type} status={value.status === true ? 'Online' : 'Offline'} ip={value.last_ip} details={'Details'} id={value.id} />
                        )
                    })
                }
            </div>

        </div>
    )
}
