import React from "react"
import './DeviseDetailsList.scss'

export default function DeviseDataBoardList({ firstText, secondText, thirdText }) {
    return (
        <div className="boardList">
            <p>{firstText}</p>
            <p>{secondText}</p>
            <p>{thirdText}</p>
        </div>
    )
}