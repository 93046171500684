import { useState, useEffect } from "react";
import NavbarBlock from "../../Blocks/NavbarBlock/NavbarBlock";
import './EditUser.scss'
import md5 from 'md5'
import Popup from "../Popup/Popup";
import EditUserPopup from "../Popup/EditUserPopup";
import EditUserPopupNone from "../Popup/EditUserPopupNone";
import {useNavigate} from "react-router-dom";



export default function EditUser({ token, onLogout }) {
    const navigate = useNavigate();
    const user_name = localStorage.getItem('user_name')



    const pathName = window.location.pathname
    const [name, setName] = useState(user_name)
    const [password, setPassword] = useState('********')
    const [login, setLogin] = useState(pathName.split('/')[4])
    const [isActive, setIsActive] = useState(pathName.split('/')[6] === 'Yes' ? true : false)
    const [isAdmin, setIsAdmin] = useState(pathName.split('/')[5] === 'Yes' ? true : false)
    let hash_password = login.toLowerCase() + password
    let hash_password_result = md5(hash_password)
    const [open, setOpen] = useState(false)
    const [open_accept, setOpenAccept] = useState(false)
    const [open_wrong, setOpenWrong] = useState(false)

    const editUser = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        let raw = JSON.stringify({
            password: hash_password_result,
            name: name,
            is_active: isActive,
            is_admin: isAdmin
        });
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            },
            body: raw,
            redirect: 'follow'
        };
        fetch(`https://apiv1.zis.ru/users/${pathName.split('/')[2]}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.result === 'success') {
                    setOpenAccept(true)
                }
                else {
                    setOpenWrong(true)
                }
            })
    }


    const deleteUser = () => {
        let accesToken = localStorage.getItem('accesToken')
        let AuthStr = 'Bearer ' + accesToken
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            },
            redirect: 'follow'
        };
        fetch(`https://apiv1.zis.ru/users/${pathName.split('/')[2]}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.result === 'success') {
                    window.location = '/admins'
                }
            })
    }

    useEffect(() => {
        let accesToken = localStorage.getItem('accesToken');
        if (!accesToken) {

            window.location.href = '/'
            return false;
        }
    }, [])





    const close = () => {
        setOpen(!open)
    }

    const closeAccept = () => {
        setOpenAccept(!open_accept)
        navigate('/admins')
    }

    const closeWrong = () => {
        setOpenWrong(!open_wrong)
    }


    return (
        <div className="addNew_admins"  >
            {open ? <Popup onClick={close} deleteUser={deleteUser} /> : ''}
            {open_accept ? <EditUserPopup onClick={closeAccept} /> : ''}
            {open_wrong ? <EditUserPopupNone onClick={closeWrong} /> : ''}
            <NavbarBlock token={token} onLogout={onLogout} />
            <div className="container_addnew_rightPart">
                <h3>Edit User</h3>
                <div className="inputDiv">
                    <p>Name:</p>
                    <input type={''} value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className="inputDiv">
                    <p>Password:</p>
                    <input type={'password'} value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="inputDiv">
                    <p>Is Admin:</p>
                    <label className="container">
                        <input type="checkbox"
                            defaultChecked={isAdmin === true ? 'checked' : ''}
                            onChange={(e) => {
                                e.target.checked ? setIsAdmin(true) : setIsAdmin(false)
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="inputDiv">
                    <p>Is Active:</p>
                    <label className="container">
                        <input type="checkbox"
                            defaultChecked={isActive === true ? 'checked' : ''}
                            onChange={(e) => {
                                e.target.checked ? setIsActive(true) : setIsActive(false)
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="buttons_div" >
                    <button
                        onClick={() => editUser()}
                    >
                        <p>Save</p>
                    </button>
                    <button
                        onClick={() => setOpen(true)}
                    >
                        <p>Delete</p>
                    </button>
                </div>

            </div>

        </div>
    )
}
